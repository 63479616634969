@import url("https://fonts.googleapis.com/css2?family=Kalam:wght@300;400;700&family=Oswald:wght@200..700&family=Tenor+Sans&display=swap");

* {
  font-family: "Oswald";
}

.title{
  font-size: 1.2em;
  font-weight: bold;
  margin: 0;
  padding: 0;
  font-family: 'Oswald';
  color: #047d4b;
}

.label-input {
  font-size: 1.1em;
  font-weight: bold;
  margin: 0;
  padding: 0;
  color: #6c7072;
  font-family: "Oswald";
  margin-bottom: -10px;
}

.label-see {
  font-size: 1em;
  margin: 0;
  padding: 0;
  color: #717070;
  font-family: "Oswald";
}

.input {
  padding: 0;
  margin: 0;
  gap: 0;
  font-family: "Oswald";
  border-radius: "0px";
  margin-top: -10;
}

.input-form {
  font-family: "Oswald";
  font-size: 1em;
  border-radius: 0px;
  margin-left: auto;
}

.input-form-see {
  font-family: "Oswald";
  font-size: 1.1em;
  border-radius: 0px;
  margin-left: auto;
  border: none;
  border-bottom: 1px solid #bcc3c9;
  pointer-events: none;
}

.input-see {
  font-family: "Oswald";
  font-size: 1.1em;
  border-radius: 0px;
  margin-left: auto;
  border: none;
  background-color: white !important;
  border-bottom: 1px solid #bcc3c9;
  padding-left: 0;
  color: #6c7072 !important;
}

.ant-form-item-control-input-content input {
  font-family: "Oswald";
  font-size: 1.1em;
}

.ant-select-single .ant-select-selector {
  font-family: "Oswald";
  font-size: 1.1em;
  border-radius: 0px;
}

.inl {
  font-family: "Oswald";
}

.button-in {
  background: #047d4b;
  margin: 0;
  border-radius: 0;
  font-size: 1.1em;
  font-family: "Oswald";
  color: white;
  width: min-content;
  margin-top: 0.5rem;
}

.button-med {
  background: #7b7f81;
  margin: 0;
  border-radius: 0;
  font-size: 1.1em;
  font-family: "Oswald";
  color: white;
  width: min-content;
  margin-top: 0.5rem;
}

.button-out {
  background: #d6661b;
  margin: 0;
  border-radius: 0;
  font-size: 1.1em;
  font-family: "Oswald";
  color: white;
  width: min-content;
  margin-top: 0.5rem;
}

.bottom-text {
  font-size: 1.1em;
  font-family: "Oswald";
  color: #6c7072;
  margin-top: 20px;
}

.menu-bar {
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: #edf3f8;
  border: 1px solid #bec9bc;
  color: #047d4b;
  font-family: "Oswald";
  font-size: 1.1em;
  padding: 0;
  margin-bottom: 1rem;
}

.menu-bar-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.first {
  background-color: white;
}

.active {
  background-color: #d4d6d7;
  color: #6c7072;
}

.menu-bar-item:hover {
  background-color: #ccd3d8;
}

.menu-bar-item-text {
  padding: 0;
  margin: 0;
}

.ant-list-item {
  align-items: flex-start !important;
  cursor: pointer;
}

.ant-list-item:hover {
  background-color: #f5f5f5;
}
